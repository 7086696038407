import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  previewContainer: {
    /* container */
    // position: 'absolute',
    // left: '0%',
    // right: '0%',
    // top: '0%',
    // bottom: '0%',
    padding: '25px',
    // maxWidth: '800px',
    background: 'rgba(155, 194, 209, 0.25)',
    border: '1px solid rgba(155, 194, 209, 0.25)',
    borderRadius: '25px',
  },
  previewLeft: {
    [theme.breakpoints.only('xs')]: {
      borderBottom: '1px solid #FFFFFF',
    },
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #FFFFFF',
      paddingLeft: '50px'
    },
  },
  previewRight: {
    [theme.breakpoints.only('xs')]: {
      borderTop: '1px solid #FFFFFF',
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid #FFFFFF',
      paddingRight: '50px'
    },
  },
  paymentContainer: {
    marginTop: '50px',
    padding: '25px',
    background: 'rgba(155, 194, 209, 0.25)',
    border: '1px solid rgba(155, 194, 209, 0.25)',
    borderRadius: '25px',
    height: 'fit-content'
  },
  formContainer: {
    padding: '10px',
  },
  formTitle: {
    marginBottom: theme.spacing(2),
  },
  formFormControl: {
    marginBottom: theme.spacing(3),
  },
  divEditButton: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    gap: '30px !important',
  },
  editButton: {
    cursor: 'pointer !important',
    border: 'none !important',
    backgroundColor: '#71ba6c !important',

    color: '#ffffff !important',
    lineHeight: '40px !important',
    height: '40px !important',
    textAlign: 'center !important',
    fontSize: '14px !important',
    borderRadius: '4px !important',
    border: 'none !important',
    padding: '0 20px !important',
    cursor: 'pointer !important',
    display: 'flex',
    alignItems: 'center',
    gridGap: '15px',
    gap: '15px',
  },

}));

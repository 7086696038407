class Types  {

    constructor({TYPES}) {
        this.TYPES =  TYPES;
        this.types = {};
        this.IDS = {};
        Object.values(this.TYPES).forEach(value => {
            this.types[value.id] = value.xlat;
            this.IDS[value.name] = value.id;
        });
    }
    
    getIdsFromNumber = (number) => {
        const binaryString = number.toString(2);
        const binaryArray = binaryString.split("");
        const binaryArrayReversed = binaryArray.reverse();
        const ids = this.getIds();
        const idsArray = [];
        binaryArrayReversed.forEach((bit, index) => {
            if (bit === "1") {
                idsArray.push(ids[index]);
            }
        });
        return idsArray;
    };

    getIds = () => [...Object.values(this.TYPES).map(types => types.id)];

    // FIXME: maybe check that id and language are valid?
    // how about merging these two functions?
    getTranslatedName = (types, id, language) => {
        return [types[id][language].name]
    };

    getTranslatedNameFromNumber = (types, number, language) => {
        const ids = this.getIdsFromNumber(number);
        console.log(ids);
        const names = ids.map(id => this.getTranslatedName(types, id, language));
        return names;
    };

    isValidId = (id) => {
        const ids = this.getIds();
        for(let i = 0; i < ids.length; i++) {
            if(Number.parseInt(id) === Number.parseInt(ids[i])) { return true; }
        }
        return false;
    };

    getId = (name) => {
        return this.IDS[name.toLowerCase()];
    };

    getLabel = (id) => {
        for (const tag in this.IDS) {
            if (parseInt(id) === this.IDS[tag]) { return tag; }
        }
    };
};

export default Types;
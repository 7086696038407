import { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useMediaQuery, Grid, Select, MenuItem, Button, ButtonGroup, Paper, Hidden } from '@material-ui/core';


import ExperienceAvailabilityPageComponent from '../ExperienceAvailabilityPage';
import Dialog from '../Dialog';
import Skeleton from '../Skeleton';
import Title from '../Title';
import BasicTable from '../Table';
import PaginationControlled from '../Pagination';
import CircularProgress from '../Progress';

import ProfiletIcon from '../../assets/icons/general/profile.svg';

import { DateFormattedUtils, TimeFormattedUtils, formatDateToYYYYMMDD } from '../../utils/dateTimeFormatted';
import { MoneyFormattedUtils } from '../../utils/moneyFormatted';

import BookingStatus from '../../../status/BookingStatus/BookingStatus';

import { useStyles } from './styles';

const BookingPageComponent = ({
    booking,
    setBooking,
    payment,
    bookingLogs,
    bookingLogsOptions,
    onSave = () => { },
    onCancel = () => { },
    showTriper = false,
    showPayment = false,
    showLogs = false,
    canEdit = false,
    hasChanged = false,
    setHasChanged = () => { },
    accionButtons = {},
    getByDateMonth = () => { },
    translate = (t, def) => { return def || t },
    ...props
}) => {
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const [openCancelReason, setOpenCancelReason] = useState(false);
    const [openTriperBooking, setOpenTriperBooking] = useState(false);
    const [openAvailability, setOpenAvailability] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedExperienceItem, setSelectedExperienceItem] = useState(null);
    const [schedule, setSchedules] = useState();
    const [dateDatePicker, setDateDatePicker] = useState(new Date());
    const [scheduleDate, setScheduleDate] = useState(() =>
        formatDateToYYYYMMDD(new Date())
    );

    useEffect(() => {
        setLoading(true);
        if (!booking) { return; }
        if (Array.isArray(booking) && booking.length === 0) { return; }
        if (!booking.experienceDetailId) { return; }
        const exp = {
            id: booking.experienceId,
            experienceDetailId: booking.experienceDetailId,
        }
        setSelectedExperienceItem(exp);
        getByDateMonth(
            booking?.experienceId,
            booking?.experienceDetailId,
            scheduleDate,
            'monthly',
            booking?.quanity,
            booking?.contractId
        ).then((res) => {
            setSchedules(res);
            setLoading(false);
        });
    }, [openAvailability, scheduleDate]);

    const setTimeOfArrival = (date) => {
        if (date) {
            setBooking({ ...booking, date });
            setHasChanged(true);
        }
    };

    const handleCloseCancelReason = () => {
        setOpenCancelReason(false);
    };

    const handleCloseTriperBooking = () => {
        setOpenTriperBooking(false);
    };

    const handleCloseSetDate = () => {
        setOpenAvailability(false);
    };

    const handleDatePickerChange = (date) => {
        setDateDatePicker(date);
        setTimeOfArrival(null);
    };

    const handleMonthChange = (date) => {
        setScheduleDate(formatDateToYYYYMMDD(date));
    };

    const renderStatus = () => {
        const status = parseInt(booking.status);
        let colorLabel = status === 4 ? ("#A6ba62") : (status === 2 ? ("#9bc2d1") : ("#E39348 "));
        return <span style={{ color: colorLabel }}> {BookingStatus.getLabel(booking.status)} </span>;
    };

    const renderTriperBooking = () => {
        if (!booking?.tripers || !booking?.tripers.length) { return; }
        return booking.tripers.map((bookingData) => {
            if (!bookingData) {
                return '';
            }
            return (
                <Grid container>
                    <Paper
                        elevation={3}
                        style={{ minWidth: '300px', padding: '5px', margin: '5px' }}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>{translate('bookingForm.triperBooking.firstname')}</h2>
                            <p>{bookingData.firstName}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>{translate('bookingForm.triperBooking.lastname')}</h2>
                            <p>{bookingData.lastName}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>Email</h2>
                            <p>{bookingData.email}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>{translate('bookingForm.triperBooking.dob')}</h2>
                            <p>{bookingData.dob.substring(0, 10)}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>{translate('bookingForm.triperBooking.nationality')}</h2>
                            <p>{bookingData.nationality}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>{translate('bookingForm.triperBooking.personalId')}</h2>
                            <p>{bookingData.personalId}</p>
                        </Grid>
                    </Paper>
                </Grid>
            );
        });
    };

    const renderCancelReason = () => {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Title size={'xl'} value={'Cancel reason'} />
                        <textarea
                            style={{ marginBottom: '2em' }}
                            onChange={(e) => {
                                setBooking({ ...booking, cancelReason: e.target.value });
                                setHasChanged(true);
                            }}
                            id="cancelReason"
                            name="cancelReason"
                            component={'textarea'}
                            label={'Cancel Reason'}
                            disabled={!canEdit}
                            multiline
                            rows={6}
                        ></textarea>
                    </Grid>
                </Grid>
            </>
        );
    };


    return (
        <>
            <Grid container justifyContent='space-between'>
                <Grid item >
                    <Title value={<>Booking code: {booking?.code} {renderStatus()}</>} />
                </Grid>
                <Grid>
                    <ButtonGroup disableElevation variant="contained" color="primary">
                        {
                            accionButtons?.setDate?.show ? (
                                <Button onClick={() => {
                                    setOpenAvailability(true);
                                }}>
                                    CHANGE DATE
                                </Button>
                            ) : ''
                        }
                        {
                            accionButtons?.setNoShow?.show ? (
                                <Button onClick={() => {
                                    setBooking({ ...booking, status: BookingStatus.getId('noshow') });
                                    setHasChanged(true);
                                }}>
                                    No Show
                                </Button>
                            ) : ''
                        }
                        {
                            accionButtons?.setCancel?.show ? (
                                <Button onClick={() => {
                                    setBooking({ ...booking, status: BookingStatus.getId('cancelled') });
                                    setOpenCancelReason(true);
                                    setHasChanged(true);
                                }}>
                                    Cancel
                                </Button>
                            ) : ''
                        }
                    </ButtonGroup>
                </Grid>
            </Grid >
            <Form
                onSubmit={onSave}
                initialValues={booking}
                render={({ handleSubmit, form }) => (
                    <form>
                        {
                            canEdit && hasChanged ? (
                                <Grid item container justifyContent="flex-end" alignContent="flex-end" style={{ padding: "25px" }}>
                                    <Grid
                                        item
                                        container
                                        justifyContent={'space-between'}
                                        style={{ width: 'auto' }}>
                                        <Button
                                            className="rounded mr-3 height-40 w-100px"
                                            disabled={!canEdit}
                                            type="button"
                                            onClick={() => {
                                                onCancel();
                                                setHasChanged(false);
                                            }}>
                                            CANCEL
                                            {/* {translate('btn_cancel')} */}
                                        </Button>

                                        <Button
                                            onClick={(e) => {
                                                handleSubmit(e);
                                                setHasChanged(false);
                                            }}
                                            className="rounded height-40 w-100px triper-button bg-green-300"
                                            disabled={!canEdit}
                                            type="submit">
                                            SAVE
                                            {/* {translate('btn_submit')} */}
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : ""
                        }
                        <Grid container alignContent='center' justifyContent='center'>
                            <Grid container className={classes.previewContainer}>
                                {/* <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid> */}
                                <Grid container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    item className={classes.previewLeft} xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <p>Exp: {booking.experienceName}</p>
                                    <p>Date: {DateFormattedUtils(booking.date, null, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        timeZone: 'UTC',
                                    })}
                                    </p>
                                    <p>Time:  {TimeFormattedUtils(booking.date)}</p>
                                    <p>Location: {booking.experienceLocation}</p>
                                </Grid>
                                <Grid container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems={isSmall ? "flex-start" : "flex-end"}
                                    item className={classes.previewRight} xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <p>{booking.firstName} {booking.lastName}</p>
                                    <p>{booking.email}</p>
                                    <p>{booking.mobilePhone}</p>
                                    <p>{booking.quantity} adults
                                        {
                                            showTriper && booking?.tripers?.length ? (
                                                <Button
                                                    style={{ marginLeft: '5px', color: 'white', backgroundColor: '#A6ba62' }}
                                                    onClick={() => {
                                                        setOpenTriperBooking(true);
                                                    }}
                                                >
                                                    <img src={ProfiletIcon} width={20} />
                                                    <Hidden only="xs">Tripers</Hidden>
                                                </Button>
                                            ) : ''
                                        }

                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent='center'>
                            <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/* // Title */}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Title size={"l"} value="Booking Details" />
                                </Grid>
                                {/* // Details */}
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Created on'} />
                                    <p>{DateFormattedUtils(booking.createdOn)}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {
                                        booking.updatedOn ?
                                            (<>
                                                <Title size={'m'} value={'Updated on'} />
                                                <p>{DateFormattedUtils(booking.updatedOn)}</p>
                                            </>) : ""
                                    }
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Origin'} />
                                    <p>{booking.originTranslated}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {
                                        booking.contractName ?
                                            (<>
                                                <Title size={'m'} value={'Contract'} />
                                                <p>{booking.contractName}</p>
                                            </>)
                                            : ""
                                    }
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {
                                        booking.host ?
                                            (<>
                                                <Title size={'m'} value={'Host'} />
                                                <p>{booking.host}</p>
                                            </>)
                                            : ""
                                    }
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {
                                        booking.hostName ?
                                            (<>
                                                <Title size={'m'} value={'Host Agent'} />
                                                <p>{booking.hostName}</p>
                                            </>)
                                            : ""
                                    }
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Total Price'} />
                                    <p>{booking.currency} {MoneyFormattedUtils(booking.price)}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Paid Price'} />
                                    <p>{booking.currency} {MoneyFormattedUtils(booking.paidPrice)}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Status'} />
                                    <p>{BookingStatus.getLabel(booking.status)}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'To Be Paid Price'} />
                                    <p>{booking.currency} {MoneyFormattedUtils(parseFloat(booking.price) - parseFloat(booking.paidPrice))}</p>
                                </Grid>
                                {
                                    parseInt(booking.status) === BookingStatus.STATUS.CANCELLED.id ?
                                        (<>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Title size={'m'} value={'Cancel reason'} />
                                                <Field
                                                    style={{ marginBottom: '2em', width: '90%' }}
                                                    onChange={(e) => {
                                                        setBooking({ ...booking, cancelReason: e.target.value });
                                                        setHasChanged(true);
                                                    }}
                                                    id="cancelReason"
                                                    name="cancelReason"
                                                    component={'textarea'}
                                                    label={'Cancel Reason'}
                                                    disabled={!canEdit}
                                                    multiline
                                                    rows={6}
                                                />
                                            </Grid>
                                        </>)
                                        : ""
                                }
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Title size={'m'} value={'Additional Comments'} />
                                    {/* <textarea style={{ width: '90%', minHeight: '100px', minWidth: '200px' }}>{booking.additional}</textarea> */}
                                    <Field
                                        style={{ marginBottom: '2em', width: '90%' }}
                                        onChange={(e) => {
                                            setBooking({ ...booking, additional: e.target.value });
                                            setHasChanged(true);
                                        }}
                                        id="additional"
                                        name="additional"
                                        component={'textarea'}
                                        label={'Additional Comments'}
                                        disabled={!canEdit}
                                        multiline
                                        rows={6}
                                    />
                                </Grid>
                            </Grid>
                            {
                                showPayment && parseFloat(booking.price) > 0 ? (
                                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container className={classes.paymentContainer}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Title size={"l"} value="Payment Details" />
                                            </Grid>
                                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Title size={'m'} value={'uTriper Comission'} />
                                                    <p>{booking.currency} {MoneyFormattedUtils(parseFloat(booking.commission))}</p>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Title size={'m'} value={'Discounts'} />
                                                    <p>{booking.discountRate || "N/A"}</p>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Title size={'m'} value={'Fee Rate'} />
                                                    <p>{booking.feeRate}%</p>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Title size={'m'} value={'Experience Pay Now'} />
                                                    <p>{booking.payNow}%</p>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                {
                                                    payment ?
                                                        (<>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Title size={'m'} value={'Created on'} />
                                                                <p>{DateFormattedUtils(payment.createdOn)}</p>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Title size={'m'} value={'Updated on'} />
                                                                <p>{DateFormattedUtils(payment.updatedOn)}</p>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Title size={'m'} value={'Payment Platform'} />
                                                                <p>{payment.paymentPlatformName}</p>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Title size={'m'} value={'Payment Status'} />
                                                                <p>{payment.status}</p>
                                                            </Grid>
                                                        </>) :
                                                        (<>
                                                            <Skeleton />
                                                            <Skeleton />
                                                            <Skeleton />
                                                            <Skeleton />
                                                        </>)
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : ""
                            }
                        </Grid>
                    </form>
                )}
            />

            {
                showLogs ? (
                    <Grid container>
                        <Title size={'m'} value={'Booking Logs'} />
                        {bookingLogs ? (
                            <>
                                <BasicTable columns={!isSmall ? bookingLogsOptions.columns : (bookingLogsOptions.mobileColumns || bookingLogsOptions.columns)} values={bookingLogs} />
                                <br />
                                <Grid container alignItems='flex-end'>
                                    <Grid item xs={1} sm={7}></Grid>
                                    <Grid item xs={11} sm={5}>
                                        {
                                            bookingLogsOptions.total / bookingLogsOptions.MAX_PAGE < 1 ? null :
                                                (<PaginationControlled count={(Math.ceil(bookingLogsOptions.total / bookingLogsOptions.MAX_PAGE))} page={bookingLogsOptions.page} onChange={bookingLogsOptions.handlePageChange} />)
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Grid container direction='row' justifyContent='center' alignItems='center'>
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        )
                        }
                    </Grid>
                ) : ""
            }

            <Dialog
                open={openAvailability}
                hideTitle={true}
                hideActions={true}
                handleClose={handleCloseSetDate}
                dialogContent={
                    <ExperienceAvailabilityPageComponent
                        loading={loading}
                        showTitle={false}
                        showQuantity={false}
                        // quantity={quantity}
                        handleQuantityChange={() => { }}
                        onChange={(date) => {
                            handleDatePickerChange(date);
                        }}
                        onMonthChange={(date) => {
                            handleMonthChange(date);
                        }}
                        value={dateDatePicker}
                        data={schedule}
                        showPrice={false}
                        showAvailability={true}
                        experience={selectedExperienceItem}
                        setTimeOfArrival={setTimeOfArrival}
                        addBookedExperienceWithQuantityPrice={(experience, price, quanity) => {
                            // addBookedExperienceWithQuantityPrice(val);
                            // addCheckoutItem(experience, price, quanity)
                            setOpenAvailability(false)
                        }}
                        onSlotSelect={(slot) => {
                            // setSelectedSlotPrice(parseFloat(slot.price));
                        }}
                    />
                }
            />
            <Dialog
                open={openTriperBooking}
                hideTitle={true}
                hideActions={true}
                handleClose={handleCloseTriperBooking}
                dialogContent={renderTriperBooking()}
            />
            <Dialog
                open={openCancelReason}
                hideTitle={true}
                handleClose={handleCloseCancelReason}
                dialogContent={renderCancelReason()}
                hideBack={true}
                labelNext='OK'
                handleSubmit={handleCloseCancelReason}
            />
        </>
    );
};

export default BookingPageComponent;

/* eslint-disable no-console */
const SESSION = '@uTriper/auth';

export const getSession = () => {
  try {

    const sessionData = localStorage.getItem(SESSION);
    console.log('sessionData', sessionData);
    if (!sessionData) {
      // lets try restore it with cookie
      throw new Error('No session');
    }

    return JSON.parse(sessionData);
  } catch (err) {
    console.error('@uTriper [getSession]: ', err.message);
    return null;
  }
};

export const setSession = (session) => {
  localStorage.setItem(SESSION, JSON.stringify(session));
};

export const removeSession = () => {
  localStorage.clear();
};

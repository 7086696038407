import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { HostService } from 'core/api/services/host';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import BasicTable from 'core/libs/core-ui/components/Table';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';
import BookingOrigin from 'core/libs/types/BookingOrigin/BookingOrigin';


const GlobalBookingsTable = ({
  experiencesId,
  email,
  bookingCode,
  showPreviousBookings,
  load,
}) => {
  const history = useHistory();
  const { t: translate } = useTranslation();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [page, setPage] = useState(1);
  const [initPage, setInitPage] = useState(-1);
  const [bookings, setBookings] = useState([]);
  const [totalBookings, setTotalBookings] = useState(0);
  const [firstSrvCall, setFirstSrvCall] = useState(true);
  const [pageReset, setPageReset] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [direction, setDirection] = useState('asc');
  const MAX_PAGE = 20;

  const emailDebounce = useDebounce(email, 300);
  const bookingCodeDebounce = useDebounce(bookingCode, 300);

  useEffect(() => {
    setFirstSrvCall(true);
  }, [showPreviousBookings, email, bookingCode]);

  useEffect(() => {
    setPage(1);
    setInitPage(1);
  }, [firstSrvCall]);

  

  useEffect(() => {
    HostService.getBookings(
      experiencesId,
      email,
      bookingCode,
      showPreviousBookings,
      page,
      MAX_PAGE,
      orderBy,
      direction
    ).then((res) => {
      const bookings = prettifyBookings(res.bookings);
      setBookings(bookings);
      setTotalBookings(res.total);
      setFirstSrvCall(false);
    });
  }, [
    experiencesId,
    emailDebounce,
    bookingCodeDebounce,
    showPreviousBookings,
    page,
    load,
    pageReset,
    orderBy,
    direction
  ]);


  // useEffect(() => {
  //   getHosts({
  //     pager: {
  //       page,
  //       pageSize: MAX_PAGE
  //     }
  //   });
  // }, [page]);

 
  const columns = [
    { align: 'left', val: 'code', label: "Booking Code", column: 'ci_code' },
    { val: 'triper', label: "Triper", column: 'ci_first_name' },
    { val: 'email', label: "Email", column: 'ci_email' },
    { val: 'experienceName', label: "Activity Name", column: "ci_experience_detail_id" },
    { val: 'dateFormatted', label: "Activity Date", column: 'ci_date' },
    { val: 'quantity', label: "Quantity", column: 'ci_quantity' },
    { align: 'center', val: 'paidPriceString', label: "Paid Ammount", column: 'ci_paid_price' },
    { align: 'center', val: 'priceString', label: "Price", column: 'ci_price' },
    { align: 'center', val: 'originString', label: "Origin Id", column: 'ci_origin_id' },
    { val: 'hostName', label: "Host Name", column: 'ci_host_name' },
    { align: 'center', val: 'statusString', label: "Status", column: 'ci_status' },
  ];

  const prettifyBookings = (bookings = []) => {
    return bookings.map((booking) => {
      return {
        triper: `${booking.firstName} ${booking.lastName}`,
        statusString: BookingStatus.getLabel(booking.status),
        originString: BookingOrigin.getLabel(booking.originId),
        priceString: `${booking.currency} ${MoneyFormattedUtils(booking.price)}`,
        paidPriceString: `${booking.currency} ${MoneyFormattedUtils(booking.paidPrice)}`,
        dateFormatted: DateFormattedUtils(booking.date),
        ...booking,
      };
    });
  };

  const handleSort = (column) => {
    if (orderBy === column) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(column);
      setDirection('asc');
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onClick = (event, id) => {
    history.push(`/booking/${id}`)
  }

  return (
    <React.Fragment>
      {initPage !== -1 && (
        <React.Fragment>
          <BasicTable
            columns={columns}
            values={bookings}
            handleSort={handleSort}
            orderBy={orderBy}
            direction={direction}
            onClick={onClick} 
            // style={
            //   isSmall
            //     ? { width: 'max-content', margin: '0 1rem' }
            //     : { margin: '0 1rem' }
            // }
            
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: () => {
            //       console.log('clicked on Table Row: ', record);
            //       setBookingModalData({
            //         experienceId: record.experienceId,
            //         experienceDetailId: record.experienceDetailId,
            //         bookingId: record.id,
            //       });
            //     },
            //   };
            // }}
          />
          <br />
          <Grid container alignItems="flex-end">
            <Grid item xs={1} sm={7}></Grid>
            <Grid item xs={11} sm={5}>
              {totalBookings / MAX_PAGE < 1 ? null : (
                <PaginationControlled
                  count={Math.ceil(totalBookings / MAX_PAGE)}
                  page={page}
                  onChange={handlePageChange}
                />
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GlobalBookingsTable;

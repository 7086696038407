import React, {useEffect, useState } from 'react';
import { Grid, Box, Paper, makeStyles } from '@material-ui/core';
// import DialogCore from '../dialog';
// import ExperienceDetails from '../experienceDetails';
import { TimeFormattedMinutesHours } from '../../../../utils/dateTimeFormatted';
import { formatCurrency } from '../../../../utils/moneyFormatted';
import { useExperiences } from 'core/contexts/experience';
import { useTranslation } from 'core/contexts/localization';

const useStyles = makeStyles((theme) => ({
    availablePaper: {
        backgroundColor: '#12a14b',
        color: 'white',
        cursor: 'pointer',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    disabledPaper: {
        backgroundColor: 'grey',
        color: 'white',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    selectedPaper: {
        backgroundColor: '#9bc2d1',
        color: 'white',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    slotContainer: {
        margin: 'auto',
    },
    gridFather: {
        display: 'flex',
        flexDirection: 'column',
    },
    paperItem: {},
    slotGrid: {},
}));

const Slots = ({ slot, quantity, daySelected, experience, addBookedExperienceWithQuantityPrice, setTimeOfArrival, onChange, setSelectedSlot,dialogState, ...props }) => {
    const { t: translate } = useTranslation();
    const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
    const classes = useStyles();

    useEffect(()=> {
    if(!dialogState){
        setSelectedSlot(null);
        setSelectedSlotIndex(null);
        setTimeOfArrival(null);
    }
    }, [slot, dialogState])

    if (!slot || !Array.isArray(slot.slots)) {
        return '';
    }
    const handleOpenDialog = async ({ index, item }) => {
        if (!item.available) {
            return;
        }
        setSelectedSlotIndex(index);
        addBookedExperienceWithQuantityPrice(
            { ...experience, ...item, id: experience?.id },
            item.price * quantity,
            quantity,
        );
        setSelectedSlot({ ...item, priceTotal: item.price * quantity });
        setTimeOfArrival(`${daySelected}T${item.time}.000Z`);
        onChange(item);
    };


    return (
        <>
            <Grid className={classes.gridFather} wrap="wrap">
                {slot.slots.map((item, index) => (
                    <Grid
                        item
                        className={classes.slotGrid}
                        xs={12}
                        md={12}
                        lg={12}
                        spacing={2}>
                        <Box className={classes.slotContainer} m={1} textAlign="center">
                            {slot.availability && (
                                <Paper
                                    key={index}
                                    className={
                                        index === selectedSlotIndex ? `${classes.selectedPaper} ${classes.paperItem}` :
                                            (item.available
                                                ? `${classes.availablePaper} ${classes.paperItem}`
                                                : `${classes.disabledPaper} ${classes.paperItem}`)
                                    }
                                    onClick={() => handleOpenDialog({ index, item })}>
                                    {TimeFormattedMinutesHours(item.time)}{' '}
                                    {index === selectedSlotIndex ?
                                        translate('slot.selected').toUpperCase() :
                                        (item.available
                                            ? formatCurrency(item.price, item.currency)
                                            : translate('slot.notAvailable'))}{' '}
                                    {index !== selectedSlotIndex && item.available && item.currency}
                                </Paper>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Slots;

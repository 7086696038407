import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Hidden, Checkbox } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import AddIcon from 'assets/icons/home/add.svg';

import Layout from 'components/Layout';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import GlobalBookingsTable from './components/BookingsTable';
import ExperienceFilter from './components/ExperienceFilter'; // We might use it later

import { ExperienceService } from 'core/api/services/experiences';

import { useTranslation } from 'core/contexts/localization';
import { useHost } from 'core/contexts/host';
import { useExperiences } from 'core/contexts/experience';
import { useAuth } from 'core/contexts/auth';
import { ROUTES } from 'core/routing/routes';

import { useStyles } from './styles';

const Bookings = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();

    const history = useHistory();

    // const { getUserInfoSync } = useAuth();

    // const { experiences, getExperiencesUpdated } = useExperiences();
    const { contracts, getContracts } = useHost();
    const [filteredExperiences, setFilteredExperiences] = useState([]);

    // fetch experience list
    useEffect(() => {
        getContracts();
    }, []);

    useEffect(() => {
        if (contracts && contracts.length) {
            let contractsProcessed = 0;
            const auxFilteredExperiences = [];
            contracts.forEach(async (contract) => {
                contractsProcessed++;
                const qrData = await ExperienceService.getByHostel(
                    contract.id.toString(16)
                );
                const { experiences } = qrData;
                for (let exp of experiences) {
                    auxFilteredExperiences.push({
                        id: exp.id,
                        name: exp.name,
                        checked: true,
                    });
                }
                if (contractsProcessed === contracts.length) {
                    setFilteredExperiences(auxFilteredExperiences);
                }
            });
        }
    }, [contracts]);

    const onChangeFilteredExperiences = (index) => {
        const auxFilteredExperiences = [...filteredExperiences];
        auxFilteredExperiences[index].checked =
            !auxFilteredExperiences[index].checked;
        setFilteredExperiences(auxFilteredExperiences);
    };

    const onChangeAllFilteredExperiences = (checked) => {
        const auxFilteredExperiences = [...filteredExperiences].map((exp) => {
            return { ...exp, checked };
        });
        setFilteredExperiences(auxFilteredExperiences);
    };

    const getFilteredExperiencesId = useMemo(() => {
        return filteredExperiences
            .filter((exp) => exp.checked)
            .map((exp) => exp.id);
    }, [filteredExperiences]);

    const getFilterExperiencesValues = useMemo(() => {
        return filteredExperiences
            .filter((exp) => exp.checked)
            .map((exp) => exp.name);
    }, [filteredExperiences]);

    const [emailSearch, setEmailSearch] = useState('');

    const onChangeEmailSearch = (event) => {
        setEmailSearch(event.target.value);
    };

    const [bookingCodeSearch, setBookingCodeSearch] = useState('');

    const onChangeBookingCodeSearch = (event) => {
        setBookingCodeSearch(event.target.value);
    };

    const [showPreviousBookings, setShowPreviousBookings] = useState(false);

    const GreenCheckbox = withStyles({
        root: {
            color: red[400],
            '&$checked': {
                color: red[600],
            },
        },
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <Layout contentClassName="content">
            <div className={clsx(classes.globalBookingsTitle)}>
                <h1 style={{ display: 'inline' }}>
                    {translate('global_bookings.title')}
                </h1>
            </div>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <div className={clsx(classes.globalBookingsFilter)}>
                        <ExperienceFilter
                            experiences={filteredExperiences}
                            onChange={onChangeFilteredExperiences}
                            values={getFilterExperiencesValues}
                            onChangeAll={onChangeAllFilteredExperiences}
                        />
                    </div>
                    <div
                        className={clsx(
                            classes.globalBookingsSearchBoxContainer
                        )}>
                        <SearchBox
                            onChange={onChangeEmailSearch}
                            value={emailSearch}
                            label={'Email'}
                            style={{ width: '14rem', marginRight: '1rem' }}
                        />
                        <SearchBox
                            onChange={onChangeBookingCodeSearch}
                            value={bookingCodeSearch}
                            label={translate('bookingForm.code')}
                            style={{ width: '5rem' }}
                        />
                    </div>
                    <div
                        className={clsx(classes.globalBookingsShowPrevious)}
                        onClick={() =>
                            setShowPreviousBookings(!showPreviousBookings)
                        }>
                        <GreenCheckbox
                            checked={!showPreviousBookings}
                            color="#2F80ED"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={(e) => {
                                setShowPreviousBookings(!showPreviousBookings);
                            }}
                        />
                        <span>
                            {translate('bookingForm.show_previous_bookings')}
                        </span>
                    </div>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained" color="primary"
                        onClick={() => history.push(ROUTES.BOOKINGS_NEW)}
                        style={{ minHeight: '35px', marginLeft: '25px', marginRight: '25px' }}
                        startIcon={<img src={AddIcon} width={20} />}>
                        <Hidden smUp>ADD</Hidden>
                        <Hidden smDown>
                            {translate('calendar.add_booking')}
                        </Hidden>
                    </Button>
                </Grid>
            </Grid>

            <GlobalBookingsTable
                experiencesId={getFilteredExperiencesId}
                email={emailSearch}
                bookingCode={bookingCodeSearch}
                showPreviousBookings={showPreviousBookings}
                load={true}
            />
        </Layout>
    );
};

export default Bookings;

import { Grid, Typography, makeStyles } from '@material-ui/core';

import ExperienceDatePicker from './components/ExperienceDatePicker';
import QuantityPicker from '../Input/QuantityPicker';
import CircularIndeterminate from '../Progress';

import { useTranslation } from 'core/contexts/localization';


const useStyles = makeStyles((theme) => ({
    gridPadre: {
        [theme.breakpoints.up('sm')]: {
            // backgroundColor: '#eff6ff',
        },
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',

        // This is the min width of the datepicker,
        // we dont want the parent to be smaller cause looks ugly on data loading
        minWidth: '312px',
        // height: '100vh',

        // This is for the loader position
        position: 'relative',
        // border: '1px solid rgba(0, 0, 0, 0.23)',
        // borderRadius: '8px',
    },
    gridTitle: {
        paddingTop: '0.5rem',
        maxWidth: '19rem',
        wordWrap: 'break-word',
    },
    gridQuantity: {
        marginBottom: '1rem',
    },
    gridExperience: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2),
    },
    loaderContainer: {
        top: '0',
        position: 'absolute',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99999999999999999999',
        background: '#f8f8f8',
        opacity: '.8'
    },
    loader: {
        // background: '#f00',
        position: 'absolute',
        left: '45%',
        top: '25%',
        width: '2em',
        height: '2em',
        backgroundSize: 'contain',
        display: 'block',
        margin: 'auto',
    },
}));

const ExperienceAvailabilityPageComponent = ({
    loading = !(data),
    quantity,
    handleQuantityChange = () => { },
    onChange = (date) => { },
    onMonthChange = (date) => { },
    value,
    data,
    showPrice = false,
    showAvailability = true,
    experience,
    addBookedExperienceWithQuantityPrice,
    setTimeOfArrival,
    onSlotSelect = (slot) => { },
    setSelectedSlot = () => { },
    showTitle = true,
    showQuantity = true,
    dialogState,
    ...props
}) => {
    console.table({
        quantity,
        value,
        data,
        showPrice,
        showAvailability,
        experience,
        showTitle,
        ...props
    });
    const classes = useStyles();
    const { t: translate } = useTranslation();
    return (
        <Grid className={classes.gridPadre} container>
            {
                loading ? (
                    <div className={classes.loaderContainer}>
                        <div className={classes.loader}>
                            <CircularIndeterminate />
                        </div>
                    </div>
                ) : ""
            }
            <Grid className={classes.gridExperience} item xs={12} sm={12} md={12}>
                {
                    showTitle ? (
                        <Grid className={classes.gridTitle}>
                            <Typography variant='h1' >{experience?.name}</Typography>
                        </Grid>
                    ) : ''
                }
                <Grid className={classes.gridExperience}>
                    {
                        showQuantity ? (
                            <>
                                <Grid>
                                    <Typography variant='h5'>{translate('slot.quantity', 'How many people?')}</Typography>
                                </Grid>
                                <QuantityPicker
                                    value={quantity}
                                    handleQuantityChange={handleQuantityChange}
                                />
                            </>
                        ) : ''
                    }
                </Grid>
                <Typography variant='h5'>{translate('slot.selectedDate', 'What date?')}</Typography>
                <ExperienceDatePicker
                    onChange={(date) => {
                        onChange(date);
                    }}
                    onMonthChange={(date) => {
                        onMonthChange(date);
                    }}
                    quantity={quantity}
                    value={value}
                    data={data}
                    showPrice={showPrice}
                    showAvailability={showAvailability}
                    experience={experience}
                    addBookedExperienceWithQuantityPrice={addBookedExperienceWithQuantityPrice}
                    setTimeOfArrival={setTimeOfArrival}
                    onSlotSelect={onSlotSelect}
                    setSelectedSlot={setSelectedSlot}
                    dialogState={dialogState}
                />
            </Grid>
        </Grid>
    );
};
export default ExperienceAvailabilityPageComponent;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${60}px)`,
      marginLeft: 60,
    },
    color: theme.palette.colors.white,
  },
  appBarShift: {
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: (props) => props.drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    height: '40px',
    width: '40px',
    [theme.breakpoints.down('sm')]: {
      color: '#fff',
      background: '#001529',
      borderRadius: '0 4px 4px 0',
      boxShadow: '2px 0 8px rgb(0 0 0 / 15%)',
      position: 'absolute',
      top: '30px',
      left: 0,
      marginLeft: 0,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px 10px 15px 45px',
    position: 'relative',
    right: 0,
  },
  role: {
    display: 'flex',
  },
  roleText: {
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.background,
  },
  roleSeparator: {
    margin: `0px ${theme.spacing()}px`,
  },

  highlightRole: {
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.background,
  },
}));

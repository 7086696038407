function DateFormattedUtils(date, language, options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: 'UTC',
},
translate= (e,text) => text ) {
  if(!date) { return translate('libs.date.noDate', 'No Date'); }

  if (!language) {
    language = 'es-ES';
  }

  const dateFormatted = new Date(date).toLocaleDateString(language, options);
  return dateFormatted;
}

function TimeFormattedUtils (date, language, options = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: 'UTC',
},
translate= (e,text) => text ) {
  if(!date) { return translate('libs.date.noDate', 'No Date'); }

  if (!language) {
    language = 'es-ES';
  }

  const dateFormatted = new Date(date).toLocaleTimeString(language, options);
  return dateFormatted;
}

function TimeDurationFormatter(minutes, translate) {
  const days = parseInt(minutes / (60 * 24));
  const hours = parseInt((minutes % (60 * 24)) / 60);
  const minutesRes = parseInt(minutes % 60);

  let result = '';

  if (days > 0) {
    result += `${days}  ${days === 1 ? translate('libs.time.day') : translate('libs.time.days')}} `;
  }

  if (hours > 0) {
    result += `${hours} ${hours === 1 ?  translate('libs.time.hour') :  translate('libs.time.hours')} `;
  }

  if (minutesRes > 0) {
    result += `${minutesRes}  ${minutesRes === 1 ?  translate('libs.time.minute'): translate('libs.time.minutes')}`;
  }
  return result;
}

const TimeFormattedMinutesHours = (timeString) => {
  if(!timeString){
    return ''
  }
  const [hours, minutes] = timeString.split(":");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
};
function compareDates(day, slotDate) {
  const dayDate = day.setHours(0, 0, 0, 0);
  const slotDay = slotDate.setHours(0, 0, 0, 0);
  return dayDate === slotDay;
}
function formatDateToYYYYMMDD(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() es base 0; enero = 0
  const day = date.getDate().toString().padStart(2, '0'); // getDate() es base 1

  return `${year}-${month}-${day}`;
}

export {
  DateFormattedUtils,
  TimeDurationFormatter,
  TimeFormattedMinutesHours,
  compareDates,
  formatDateToYYYYMMDD,
  TimeFormattedUtils
};
import {
    FormControl,
    FormHelperText,
    Select,
    InputLabel,
    MenuItem,
    ListItemText,
} from '@material-ui/core';

const SelectComponent = ({
    id,
    value,
    values,
    label,
    labelId,
    style,
    onChange,
    hasError,
    errorMessage,
    ...props
}) => {

    return (
        <FormControl
            error={hasError}
            variant="outlined"
            style={style}
            >
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                id={id}
                labelId={labelId}
                value={value}
                // style={{
                //     width: '20rem',
                //     color: 'black'
                // }}
                // MenuProps={{
                //     getContentAnchorEl: () => null,
                //     PaperProps: {
                //         style: {
                //             maxHeight: '20rem',
                //             width: '20rem',
                //         },
                //     },
                // }}
                >
                {values.map((val, index) => {
                    return (
                        <MenuItem
                            key={val.id}
                            value={val.id}
                            onClick={(event) => onChange(val)}
                            // style={{ whiteSpace: 'break-spaces' }}
                            >
                            <ListItemText
                                primary={val.name || val.value}
                                // style={{ color: 'black', wordBreak: 'break-word' }}
                            />
                        </MenuItem>
                    );
                })}
            </Select>
            {hasError ? (
                <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {errorMessage}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};
export default SelectComponent;

import React, { useState } from 'react';
import { Grid, Badge, Typography, makeStyles, Box } from '@material-ui/core';
import DatePicker from '../../../Input/DatePicker';
import Slots from '../Slots';
import {
  compareDates,
  formatDateToYYYYMMDD,
} from '../../../../utils/dateTimeFormatted';
import { useStyles } from './styles';

// TODO : Modularize it once it has been tested and needs to be reused.
function formatDay(date) {
  const year = date.getFullYear();
  let month = date.getMonth() + 1; 
  let day = date.getDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
}

const ExperienceDatePicker = ({
  data = [],
  showAvailability = false,
  showPrice = false,
  quantity,
  experience,
  addBookedExperienceWithQuantityPrice,
  setTimeOfArrival,
  onSlotSelect,
  setSelectedSlot,
  dialogState,
  ...props
}) => {
  const classes = useStyles();
  const [slotSelected, setSlotSelected] = useState([]);
  const [selectedDay, setSelectedDate] = useState('');

  const renderDayContent = (
    day,
    selectedDate,
    isInCurrentMonth,
    dayComponent
  ) => {

    const dayFormatted = formatDay(day);
    const selectedDayFormatted = formatDay(selectedDate);

    const slotsForDay = data.find(slot => slot.date === dayFormatted);

    const dateToFo = formatDateToYYYYMMDD(selectedDate);
    setSelectedDate(dateToFo);
    
    
    setSlotSelected(() => {
      return data.find((slot) => selectedDayFormatted === slot.date
      );
    });

    let borderColor = classes.defaultBorderColor;
    if (slotsForDay?.availability) {
      borderColor = classes.backgroundGreen;
    }
    const isDaySelected = compareDates(day, selectedDate);

    const customDay = React.cloneElement(dayComponent, {
      ...dayComponent.props,
      children: (
        <Grid className={isDaySelected ? classes.selectedDay : borderColor}>
          <Grid className={classes.selectedDaySize}>
            {dayComponent.props.children}
          </Grid>
          {showPrice ? (
            <Typography className={classes.customPrice}>
              {slotsForDay?.price ? `$${slotsForDay.price}` : '\u00A0'}
            </Typography>
          ) : (
            <Typography className={classes.customPrice}>&nbsp;</Typography>
          )}
        </Grid>
      ),
    });
    return customDay;
  };

  return (
    <Grid className={classes.gridPadre}>
      <Box className={classes.gridDataPicker}>
        <DatePicker
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
            renderDayContent(day, selectedDate, isInCurrentMonth, dayComponent)
          }
          {...props}
        />
      </Box>
      <Box className={classes.slotsContainer}>
        <Slots
          experience={experience}
          quantity={quantity}
          slot={slotSelected}
          daySelected={selectedDay}
          addBookedExperienceWithQuantityPrice={
            addBookedExperienceWithQuantityPrice
          }
          setTimeOfArrival={setTimeOfArrival}
          onChange={onSlotSelect}
          setSelectedSlot={setSelectedSlot}
          dialogState={dialogState}
        />
      </Box>
    </Grid>
  );
};

export default ExperienceDatePicker;

import React, { useContext, useState } from 'react';
import { HostService } from '../api/services/host';

const HostContext = React.createContext(null);

const initialPaymentPlatforms = {
    payByCash: false,
    payByTerminal: false,
    payByLink: false,
};

export const HostProvider = ({ children }) => {
    const [loading_, setLoading] = useState(false);
    const [code, setCode] = useState(null);
    const [data, setData] = useState(null);
    const [paymentPlatforms, setPaymentPlatforms] = useState(initialPaymentPlatforms);
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [total, setTotal] = useState(0);

    // const getContractExperiences = async (expId) => {
    //     try {
    //       setLoading(true);
    //       const experiencesData = await ExperienceService.getUpdated(expId || '');
    
    //       if (!experiencesData) {
    //         throw new Error();
    //       }
    //       // Home experiences expects you to set the state
    //       setContractExperiences(experiencesData);
    //       // Global bookings expects you to return the experiencesData
    //       return experiencesData;
    //     } catch (err) {
    //       console.error(err);
    //       return false;
    //     } finally {
    //       setLoading(false);
    //     }
    //   };
    
    const getPaymentPlatforms = async () => {
        // try {

        //     setLoading(true);
        //     const auxPyamentPlatforms = initialPaymentPlatforms;
        //     const usr = getUserInfoSync();
        //     if (usr.host) {
        //         auxPyamentPlatforms = {
        //             payByCash: usr.host.payByCash,
        //             payByTerminal: usr.host.payByTerminal,
        //             payByLink: usr.host.payByLink,
        //         }
        //         setPaymentPlatforms(auxPyamentPlatforms);
        //     }
        //     return contracts;
        // } catch (err) {
        //   console.error(err);
        //   return false;
        // } finally {
        //   setLoading(false);
        // }
    };

    const getContracts = async () => {
        try {
          setLoading(true);
          const {contracts, total} = await HostService.getContracts();
    
          if (!contracts) {
            throw new Error();
          }
          // Home contracts expects you to set the state
          setContracts(contracts);
          setTotal(total||0);
          if(contracts && contracts[0]) {
            setContract(contracts[0]);
          }

          return contracts;
        } catch (err) {
          console.error(err);
          return false;
        } finally {
          setLoading(false);
        }
    };

   

    return (
        <HostContext.Provider
            value={{
                code,
                setCode,
                data, 
                setData,
                paymentPlatforms,
                setPaymentPlatforms,
                getPaymentPlatforms,
                contract,
                contracts,
                setContract,
                getContracts,
            }}>
            {children}
        </HostContext.Provider>
    );
};

HostProvider.displayName = 'HostProvider';

export const useHost = () => useContext(HostContext);

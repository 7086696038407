
import placeholder from '../../../assets/images/experiences/placeholder.png';
// import { parseEstablishmentToModel } from './hostel';

// called by getMediaById on experiences service
// Called on open experience modal card on landing page
export const parseExperienceMediaModel = (media) => {
  return {
    id: media.id,
    type: media.type, // 1 is image, 2 is video, 3 is youtube url
    src: media.src,
  };
};

export const parseExperienceToModel = (experience) => {
  return {
    id: experience.id,
    experiencerName: experience.experiencerName,
    expDetailId: experience.expDetail ? experience?.expDetail[0]?.id : null,
    experiencerId: experience.experiencerId,
    name: experience.name,
    subtitle: experience.description1 ?? '',
    description: experience.description2 ?? '',
    status: experience.status,
    // display placeholder image for ExperienceCard on Landing if experience has no pictureURL
    image: experience.pictureURL
      ? `${process.env.REACT_APP_CDN_URL}${experience.pictureURL}`
      : placeholder,
    location: experience.location,
    moduses: experience.moduses,
    fromPrice: experience.fromPrice,
    discount: experience.discount,
    originalPrice: experience.originalPrice,
    cancelPolicies: experience.cancelPolicies,
    // cancelPoliciesOther: experience.cancelPoliciesOther,
    envPolicies: experience.envPolicies,
    envPoliciesOther: experience.envPoliciesOther,
    includesPolicies: experience.include,
    includesPoliciesOther: experience.includeOther,
    anticipation: experience.anticipation,
    payNow: experience.payNow, // in percentage %
    // el proximo campo es solo para la lista (asi posee una unique key)
    key: experience.id,
    requireId: experience.requireId ? true : false,
    defaultPrice: experience.price,
    defaultCurrency: experience.currency,
  };
};

export const parseQrDataToModel = (qrData) => {
  return {
    // establishmentData: parseEstablishmentToModel(qrData),
    experiences: qrData.experiences.map(parseExperienceToModel),
  };
};

export const parseExperienceToObject = (data) => ({
  ...data,
});

import { node, string } from 'prop-types';
import clsx from 'clsx';

import Menu from 'components/Menu';
import AppBar from 'components/AppBar';
import { useLayout } from './hook';

const Layout = ({ children, contentClassName, isHome = false }) => {
  const { classes, open, toggleMenu, saved } = useLayout();

  return (
    <>
      {/* <AppBar
        open={open}
        toggleMenu={toggleMenu}
        saved={saved}
        isHome={isHome}
      /> */}
      <Menu open={open} toggleMenu={toggleMenu} />
      <main
        // style={{ marginTop: '56px' }}
        className={clsx(
          classes.content,
          open && classes.contentShift,
          contentClassName
        )
        }>
        {children}
      </main>
    </>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  contentClassName: string,
};

Layout.displayName = 'Layout';

export default Layout;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import {
    useMediaQuery,
    Grid,
    Box,
    Button,
    InputAdornment,
    Tooltip,
    Chip,
    Typography,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

import Summary from './components/Summary';
import Layout from 'components/Layout';

import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import Select from 'core/libs/core-ui/components/Input/Select';
import Dialog from 'core/libs/core-ui/components/Dialog';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import QuantityPicker from 'core/libs/core-ui/components/Input/QuantityPicker';
import ExperienceAvailabilityPageComponent from 'core/libs/core-ui/components/ExperienceAvailabilityPage';
import { formatDateToYYYYMMDD } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';
import { useExperiences } from 'core/contexts/experience';
import { useHost } from 'core/contexts/host';
import { useBookings } from 'core/contexts/booking';

import { ScheduleService } from 'core/api/services/schedule';

import { formatMoney } from 'core/packages/format';

import { ROUTES } from 'core/routing/routes';

const initialStateValues = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    start: '',
    end: '',
    mobilePhone: undefined,
    quantity: 1,
    date: '',
    price: undefined,
    status: undefined,
    currency: 'COP',
    repeat: false,
    mobilePhoneCountryCode: '',
    paidPrice: undefined,
    arrivalTime: undefined,
    isArrivalTimeInRange: false,
    arrivalTimeFormatted: new Date(),
    hostName: '',
};

// TODO move this to libs
function isValidNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}

const BookingNewPage = (props) => {
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const { t: translate } = useTranslation();
    const formEndRef = useRef(null);
    const scheduleEndRef = useRef(null);

    const { getUserInfoSync } = useAuth();
    const {
        contractExperiences,
        getContractExperiences,
        contractBundleExperiences,
        getContractBundleExperiences,
        timeOfArrival,
        setTimeOfArrival,
    } = useExperiences();
    const {
        contract,
        setContract,
        contracts,
        getContracts,
        paymentPlatforms,
        setPaymentPlatforms,
    } = useHost();
    const {
        addContractBooking,
        addContractBookingPayment,
        deleteContractBookingPayment,
        checkoutItems,
        setCheckoutItems,
        addCheckoutItem,
    } = useBookings();

    const [onFirstCall, setOnFirstCall] = useState(true); // This is used when having an experience id on url
    const [selectedExperienceItem, setSelectedExperienceItem] = useState(null); // This is used to get the availability
    const [selectedItem, setSelectedItem] = useState(null); // This is used for the dropdown selection
    const [allExpAndBundles, setAllExpAndBundles] = useState([]); // This is used for dropdown display and selection
    const [checkoutId, setCheckoutId] = useState(0); // This is unique id creator

    const [values, setValues] = useState(initialStateValues);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [quantityOpen, setQuantityOpen] = useState(false);
    const [quantityOpenOneTime, setQuantityOpenOneTime] = useState(true);
    const [showErrorPrice, setShowErrorPrice] = useState(false);
    
    // REVIEW THIS
    // const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [selectedSlotPrice, setSelectedSlotPrice] = useState(null);

    const [payByCashColor, setPayByCashColor] = useState('default');
    const [payByTerminalColor, setPayByTerminalColor] = useState('default');
    const [payByLinkColor, setPayByLinkColor] = useState('default');
    const [paymentPlatformId, setPaymentPlatformId] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [terminalPaymentId, setTerminalPaymentId] = useState(null);

    const [bookingId, setBookingId] = useState(null);
    const [bookingCode, setBookingCode] = useState(null);
    const [payNowPrice, setPayNowPrice] = useState(null);
    const [payTotalPrice, setPayTotalPrice] = useState(null);

    // ---
    const [loading, setLoading] = useState(false);
    const [schedule, setSchedules] = useState();
    const [quantity, setQuantity] = useState(1);
    const [dateDatePicker, setDateDatePicker] = useState(new Date());
    const [scheduleDate, setScheduleDate] = useState(() =>
        formatDateToYYYYMMDD(new Date())
    );
    // UNTIL HERE

    // Get url params
    const { id } = useParams();
    const expIndex = parseInt(id || 0);

    const scrollToSlot = () => {
        // We use this to scroll down when in mobile
        if (isSmall) {
            scheduleEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToBottom = () => {
        // We use this to scroll down when in mobile
        if (isSmall) {
            formEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        getUserInfoSync().then(async (usr) => {
            if (usr.host) {
                const auxPaymentPlatforms = {
                    payByCash: parseInt(usr.host.payByCash) === 1,
                    payByTerminal: parseInt(usr.host.payByTerminal) === 1,
                    payByLink: parseInt(usr.host.payByLink) === 1,
                };
                setPaymentPlatforms(auxPaymentPlatforms);
            }
            if (!contract || !contract.id) {
                const contracts = await getContracts();
                if (contracts && contracts.length) {
                    await getContractExperiences(contracts[0].id);
                    await getContractBundleExperiences(contracts[0].id);
                }
            } else {
                await getContractExperiences(contract.id);
                await getContractBundleExperiences(contract.id);
            }
        });
    }, []);

    useEffect(() => {
        if (!contract || !contract.id) {
            getContracts().then((contracts) => {
                if (contracts && contracts.length) {
                    getContractExperiences(contracts[0].id);
                    getContractBundleExperiences(contracts[0].id);
                }
            });
        } else {
            getContractExperiences(contract.id);
            getContractBundleExperiences(contract.id);
        }
    }, [contract]);

    useEffect(() => {
        if(onFirstCall) {
            const auxCheckout = [];
            setCheckoutItems(auxCheckout);
            setTimeOfArrival(null);
            setSelectedSlotPrice(null);
            setSelectedItem(null);
            setSelectedExperienceItem(null);
        }

        const allExp = [];
        let uniqueId = 0;
        if (contractExperiences && contractExperiences.length) {
            contractExperiences.forEach((exp) => {
                allExp.push({ ...exp, experienceId: exp.id, id: uniqueId });
                if (expIndex === exp.id && onFirstCall) {
                    setOnFirstCall(false);
                    let auxChkId = checkoutId;
                    exp.checkoutId = auxChkId;
                    exp.experienceId = exp.id;
                    exp.id = uniqueId;
                    setSelectedExperienceItem({ ...exp });
                    setSelectedItem(uniqueId);
                    const auxCheckout = checkoutItems;
                    auxCheckout.push(exp);
                    setCheckoutItems(auxCheckout);
                    auxChkId++;
                    setCheckoutId(auxChkId);
                }
                uniqueId++;
            });
        }
        if (contractBundleExperiences && contractBundleExperiences.length) {
            contractBundleExperiences.forEach((bld) => {
                bld.experiences?.forEach((exp) => {
                    exp.expDetailId = exp.id;
                    exp.experienceId = exp.expId;
                    exp.id = uniqueId;
                    uniqueId++;
                });
                allExp.push({
                    ...bld,
                    name: `PAQUETE: ${bld.name}`,
                    bundleId: bld.id,
                    id: uniqueId,
                });
                uniqueId++;
            });
        }
        setAllExpAndBundles(allExp);
    }, [contractExperiences, contractBundleExperiences]);

    useEffect(() => {
        setLoading(true);
        if (!selectedExperienceItem) {
            return;
        }
        ScheduleService.getByDateMonth(
            selectedExperienceItem.experienceId,
            selectedExperienceItem.expDetailId,
            scheduleDate,
            'monthly',
            quantity,
            contract.id
        ).then((res) => {
            setSchedules(res);
            setLoading(false);
        });
    }, [scheduleDate, selectedExperienceItem, quantity]);

    useEffect(() => {
        scrollToSlot();
    }, [schedule]);

    useEffect(() => {
        scrollToBottom();
    }, [dateDatePicker, timeOfArrival]);

    useEffect(() => {
        const auxCheckout = checkoutItems.map((it) => {
            if (it.checkoutId === selectedExperienceItem?.checkoutId) {
                it.date = timeOfArrival;
            }
            if (it.bundleId) {
                it.experiences?.forEach((itExp) => {
                    if (
                        itExp.checkoutId === selectedExperienceItem?.checkoutId
                    ) {
                        itExp.date = timeOfArrival;
                    }
                });
            }
            return { ...it };
        });
        setCheckoutItems(auxCheckout);
    }, [timeOfArrival]);
    
    useEffect(() => {
        if(!isValidNumber(selectedSlotPrice)) { return; }
        const auxPrice = selectedSlotPrice;
        setSelectedSlotPrice(null);
        const auxCheckout = checkoutItems.map((it) => {
            if (it.checkoutId === selectedExperienceItem?.checkoutId) {
                it.slotPrice = auxPrice;
            }
            if (it.bundleId) {
                it.experiences?.forEach((itExp) => {
                    if (
                        itExp.checkoutId === selectedExperienceItem?.checkoutId
                    ) {
                        itExp.slotPrice = auxPrice;
                    }
                });
            }
            return { ...it };
        });
        setCheckoutItems(auxCheckout);
    }, [selectedSlotPrice]);

    const handleDatePickerChange = (date) => {
        setDateDatePicker(date);
        setTimeOfArrival(null);
    };

    const handleMonthChange = (date) => {
        setScheduleDate(formatDateToYYYYMMDD(date));
    };

    const handleQuantityChange = (newValue) => {
        let changeSummary = false;
        const auxCheckoutItems = checkoutItems.map(it => {
            if(it.bundleId) {
                it.experiences?.forEach(exp => {
                    if (exp.date) {
                        delete exp.date;
                        changeSummary=true;
                    }
                })
            } else {
                if (it.date) {
                    delete it.date;
                    changeSummary=true;
                }
            }
            return it;
        })
        if(changeSummary) {
            setCheckoutItems(auxCheckoutItems);
            setQuantityOpen(true);
        }
        const auxValues = {
            ...values,
            quantity: newValue,
        };
        setValues(auxValues);
        setQuantity(newValue);
    };

    const handleSubmit = async (e) => {
        if (!paymentPlatformId && parseFloat(selectedSlotPrice) !== 0) {
            setAlertOpen(true);
            return;
        }

        const data = {
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            quantity: values.quantity,
            status: values.status,
            // expId: props.expId,
            // subExpId: props.subExpId,
            // calendarView: props.calendarView,
            mobilePhone: values.mobilePhone,
            mobilePhoneCountryCode: values.mobilePhoneCountryCode,
            // price: values.price, // Why this!?
            // paidPrice: values.paidPrice,
            hostName: values.hostName,
            // date should be --> 2022-08-20T09:30:00
            // shouldnt the 1st part be the selected date @ calendar?
            // not sure how the service call is received
            // 'props.from.slice(0, idx of T)' --> start date of experience -->
            // 'timeOfArrival' --> HH:mm:ss --> '09:30:00'
            // date: `${props.from.slice(0, props.from.indexOf('T'))}T${timeOfArrival}`,
            code: contract?.id || null,
            paymentPlatform: paymentPlatformId,
            items: checkoutItems,
        };

        // TODO: we pass expIds because of horrible parsing - REVIEW Later
        const { bookingId, bookingCode, paymentId, payNow, payTotal } =
            await addContractBooking(data.experienceId, data.expDetailId, data);
        if (!bookingId) {
            toast.error(`Hubo un error al intentar crear la reserva!`, {
                position: 'top-right',
                theme: 'colored',
            });
            const auxCheckout = [];
            setCheckoutItems(auxCheckout);
            history.push(ROUTES.BOOKINGS);
        } else {
            setBookingId(bookingId);
            setBookingCode(bookingCode);
            setPaymentId(paymentId);
            setPayNowPrice(payNow);
            setPayTotalPrice(payTotal);
            if (parseFloat(payNow) > 0) {
                setOpen(true);
            } else {
                setSuccessOpen(true);
            }
        }
    };

    const required = (value) => {
        return value ? undefined : translate('form.required');
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
            id: expIndex,
        });
    };

    const onSubmitConfirmPayment = async (e) => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === 3) {
            hideUID = true;
        } // TODO FIXME We need to use status lib
        if (!terminalPaymentId && !hideUID) {
            return;
        }
        await addContractBookingPayment({
            paymentId,
            paymentUID: terminalPaymentId,
            status: 'approved',
            statusId: 3,
        });
        setSuccessOpen(true);
    };

    const onDismissConfirmPayment = async (e) => {
        // Call api to delete payment initiation
        if (!paymentId) {
            return;
        }
        await deleteContractBookingPayment({ paymentId });
    };

    const renderBookingForm = () => {
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={{ ...values }}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {/* FORM ROWS */}
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate(
                                            'bookingForm.firstname'
                                        )}
                                        name="firstname"
                                        value={values.firstName}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate(
                                            'bookingForm.lastname'
                                        )}
                                        name="lastname"
                                        value={values.lastName}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                    />
                                </Grid>
                            </Grid>
                            {/* </div> */}
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={(email) => {
                                            if (!email) {
                                                return translate(
                                                    'form.required'
                                                );
                                            }
                                            if (!validateEmail(email)) {
                                                return translate(
                                                    'form.invalid.email'
                                                );
                                            }
                                            return undefined;
                                        }}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label="Email"
                                        name="email"
                                        value={values.email}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                        id="phone"
                                        onFieldChange={(value, country) => {
                                            const auxValues = {
                                                ...values,
                                                mobilePhone: value,
                                                mobilePhoneCountryCode: country,
                                            };
                                            setValues(auxValues);
                                        }}
                                        // className="add-experience__capacity"
                                        name="mobilePhone"
                                        component={PhoneNumber}
                                        hintText={translate('profile.phone')}
                                        label={
                                            translate('bookingForm.phone') +
                                            ' *'
                                        }
                                        initialCountry={'us'}
                                        validateField={(value, countryCode) =>
                                            value.length > 0 &&
                                            value.startsWith(countryCode) &&
                                            value.length > countryCode.length
                                        }
                                    />
                                </Grid>
                                {/* 
                                    <Field
                                        className="add-experience__capacity"
                                        variant="outlined"
                                        label="Phone"
                                        name="mobilePhone"
                                        value={values.mobilePhone}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                    /> 
                                */}
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {/* 
                                    <Field
                                        required
                                        validate={(number) => {
                                            if (!number) {
                                                return translate('form.required');
                                            }
                                            if (
                                                isNaN(number) ||
                                                !Number.isInteger(parseInt(number)) ||
                                                Number(number) % 1 !== 0 ||
                                                parseInt(number) <= 0
                                            ) {
                                                return translate('form.invalid.ticket_number');
                                            }
                                            return undefined;
                                        }}
                                        className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate('bookingForm.tickets')}
                                        name="quantity"
                                        type="Number"
                                        value={values.quantity}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                        disabled
                                    /> 
                                */}
                                    <Field
                                        style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                        required
                                        validate={required}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate(
                                            'bookingForm.hostname'
                                        )}
                                        name="hostName"
                                        value={values.hostName}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                        Input
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Tooltip
                                                        title={translate(
                                                            'bookingForm.hostnameHint'
                                                        )}>
                                                        <HelpOutlineIcon />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {/* PAYMENT METHOS */}
                            <Grid container direction='column' spacing={3} style={{marginTop: '25px', marginBottom: '25px'}}>
                                <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    >
                                    <Typography gutterBottom variant="h3">
                                        {' '}
                                        Por favor elige un método de pago...
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                    >
                                    <Grid
                                        item
                                        component={Box}
                                        hidden={!paymentPlatforms.payByCash}>
                                        <Chip
                                            label="Efectivo"
                                            color={payByCashColor}
                                            onClick={() => {
                                                setPayByCashColor('primary');
                                                setPayByTerminalColor(
                                                    'default'
                                                );
                                                setPayByLinkColor('default');
                                                setPaymentPlatformId(3);
                                            }}
                                            icon={<AttachMoneyIcon />}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        component={Box}
                                        hidden={
                                            !paymentPlatforms.payByTerminal
                                        }>
                                        <Chip
                                            label="Datáfono"
                                            color={payByTerminalColor}
                                            onClick={() => {
                                                setPayByCashColor('default');
                                                setPayByTerminalColor(
                                                    'primary'
                                                );
                                                setPayByLinkColor('default');
                                                setPaymentPlatformId(4);
                                            }}
                                            icon={<CreditCardIcon />}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        component={Box}
                                        hidden={!paymentPlatforms.payByLink}>
                                        <Chip
                                            label="Link de Pago"
                                            color={payByLinkColor}
                                            onClick={() => {
                                                setPayByCashColor('default');
                                                setPayByTerminalColor(
                                                    'default'
                                                );
                                                setPayByLinkColor('primary');
                                                setPaymentPlatformId(5);
                                            }}
                                            icon={<InsertLinkIcon />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* BUTTONS */}
                            <Grid container spacing={3} justifyContent='flex-end' style={{marginTop:'25px'}}>
                                <Grid item>
                                    <Button
                                        variant="contained" color="default"
                                        onClick={(event) => {
                                            const auxCheckout = [];
                                            setCheckoutItems(auxCheckout);
                                            history.push(ROUTES.BOOKINGS);
                                        }}
                                        type="button"
                                        >
                                        {translate('trip.create.buttons.cancel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained" color="primary"
                                        disabled={showErrorPrice}
                                        type="submit"
                                        >
                                        {translate('form.continue')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
                <Dialog
                    open={open}
                    dialogTitle={'Booking Payment'}
                    dialogContent={renderPaymentDialog()}
                    handleClose={() => {
                        // DELETE BOOKING!!!
                        // setExpToDelete(null);
                        onDismissConfirmPayment();
                        setOpen(false);
                    }}
                    handleSubmit={onSubmitConfirmPayment}
                    labelBack="Cancel"
                    labelNext="Confirm"
                />
                <Dialog
                    open={alertOpen}
                    dialogTitle={'Booking Payment'}
                    hideTitle={true}
                    dialogContent={'Por favor elige un método de pago...'}
                    handleClose={() => {
                        setAlertOpen(false);
                    }}
                    handleSubmit={() => setAlertOpen(false)}
                    labelBack="Cancel"
                    hideBack={true}
                    labelNext="Ok"
                />
                <Dialog
                    open={successOpen}
                    dialogTitle={'Booking Confirmation'}
                    hideTitle={true}
                    dialogContent={`Reserva realizada con éxito! Codigo: ${bookingCode}`}
                    handleClose={() => {
                        setSuccessOpen(false);
                        const auxCheckout = [];
                        setCheckoutItems(auxCheckout);
                        history.push(ROUTES.BOOKINGS);
                    }}
                    handleSubmit={() => {
                        setSuccessOpen(false);
                        const auxCheckout = [];
                        setCheckoutItems(auxCheckout);
                        history.push(`${ROUTES.BOOKINGS}/${bookingId || ''}`);
                    }}
                    labelBack="Cancel"
                    hideBack={true}
                    labelNext="Ok"
                />
            </>
        );
    };

    const renderPaymentDialog = () => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === 3) {
            hideUID = true;
        }
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        {' '}
                        Precio Total:{' '}
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
                        {' '}
                        COP$ {formatMoney(payTotalPrice)}{' '}
                    </Grid>
                </Grid>
                <Grid
                    container
                    style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        {' '}
                        Monto a cobrar(reserva de cupo):{' '}
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
                        {' '}
                        COP$ {formatMoney(payNowPrice)}{' '}
                    </Grid>
                </Grid>
                <div hidden={hideUID}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form
                                onSubmit={() => {}}
                                // initialValues={{ ...values }}
                                render={({ handleSubmit, values }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        // noValidate
                                        style={
                                            isSmall
                                                ? { width: '80vw' }
                                                : { width: 'auto' }
                                        }>
                                        <Field
                                            required
                                            // validate={required}
                                            className="add-experience__capacity"
                                            variant="outlined"
                                            label={
                                                'Codigo único de pago del datáfono'
                                            }
                                            name="paymentId"
                                            value={''}
                                            onTextFieldChange={(e) => {
                                                const { name, value } =
                                                    e.target;
                                                setTerminalPaymentId(value);
                                            }}
                                            component={TextInput}
                                        />
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

    const renderColumn = (title, children) => {
        return (
            <div style={{ paddingRight: '2rem' }}>
                <h2>{title}</h2>
                {children}
            </div>
        );
    };

    return (
        <Layout contentClassName="content">
            <Grid container>
                <Grid
                    container
                    style={{
                        padding: '1rem',
                        minWidth: '375px' /**This for the datepicker style*/,
                    }}
                    item
                    xs={12}
                    sm={4}
                    md={6}
                    lg={4}
                    xl={4}>
                    <Grid container style={{ maxHeight: '400px' }}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ marginBottom: '1rem' }}>
                            {renderColumn(
                                translate('contractSelect.contract'),
                                <Select
                                    id={'contract'}
                                    values={contracts}
                                    onChange={(val) => {
                                        // setExperienceId(null);
                                        setContract(val);
                                    }}
                                    value={contract?.id || null}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ marginBottom: '1rem' }}>
                            {renderColumn(
                                translate(
                                    'global_bookings.add_an_experience',
                                    'Agregar experiencias o paquetes'
                                ),
                                <Select
                                    id={'experience'}
                                    values={allExpAndBundles}
                                    onChange={(e) => {
                                        const val = { ...e };
                                        let auxChkId = checkoutId;
                                        val.checkoutId = auxChkId;
                                        if (val.bundleId) {
                                            val.experiences.forEach((exp) => {
                                                auxChkId++;
                                                exp.checkoutId = auxChkId;
                                            });
                                            setSelectedExperienceItem({
                                                ...val.experiences[0],
                                            });
                                        } else {
                                            setSelectedExperienceItem({
                                                ...val,
                                            });
                                        }
                                        setSelectedItem(val.id);
                                        const auxCheckout = checkoutItems;
                                        auxCheckout.push(val);
                                        setCheckoutItems(auxCheckout);

                                        auxChkId++;
                                        setCheckoutId(auxChkId);
                                    }}
                                    value={selectedItem}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ marginBottom: '1rem' }}>
                            {renderColumn(
                                translate(
                                    'global_bookings.quanity',
                                    'Cuantas personas'
                                ),
                                <QuantityPicker
                                    value={quantity}
                                    handleQuantityChange={handleQuantityChange}
                                    disabled={checkoutItems.length}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!selectedExperienceItem ? (
                        ''
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ marginBottom: '1rem' }}>
                            {renderColumn(
                                `${translate('global_bookings.pick_a_date')}`,
                                <ExperienceAvailabilityPageComponent
                                    loading={loading}
                                    showQuantity={false}
                                    quantity={quantity}
                                    handleQuantityChange={handleQuantityChange}
                                    onChange={(date) => {
                                        handleDatePickerChange(date);
                                    }}
                                    onMonthChange={(date) => {
                                        handleMonthChange(date);
                                    }}
                                    value={dateDatePicker}
                                    data={schedule}
                                    showPrice={false}
                                    showAvailability={true}
                                    experience={selectedExperienceItem}
                                    setTimeOfArrival={setTimeOfArrival}
                                    addBookedExperienceWithQuantityPrice={(
                                        experience,
                                        price,
                                        quanity
                                    ) => {
                                        // addBookedExperienceWithQuantityPrice(val);
                                        addCheckoutItem(
                                            experience,
                                            price,
                                            quanity
                                        );
                                    }}
                                    onSlotSelect={(slot) => {
                                        // setSelectedSlotId(slot.id);
                                        setSelectedSlotPrice(
                                            parseFloat(slot.price)
                                        );
                                    }}
                                />
                            )}
                            {/* This for the autoscrollin */}
                            <div ref={scheduleEndRef} />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    style={{ padding: '1rem' }}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={8}
                    xl={8}>
                    {checkoutItems.length ? (
                        <>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{ marginBottom: '1rem' }}>
                                {/* {renderColumn( */}
                                <h2>
                                    {translate(
                                        'global_bookings.booking_summary',
                                        'Resumen'
                                    )}
                                </h2>
                                <Summary
                                    showErrorPrice={showErrorPrice}
                                    setShowErrorPrice={setShowErrorPrice}
                                    quantity={quantity}
                                    checkoutId={checkoutId}
                                    setCheckoutId={setCheckoutId}
                                    checkoutItems={checkoutItems}
                                    setCheckoutItems={setCheckoutItems}
                                    setSelectedExperienceItem={
                                        setSelectedExperienceItem
                                    }
                                    setSelectedItem={setSelectedItem}
                                    onChangeDate={(val) => {
                                        setSelectedExperienceItem({ ...val });
                                        const exist = allExpAndBundles.find(
                                            (el) => el.id === val.id
                                        );
                                        if (exist) {
                                            setSelectedItem(val.id);
                                        }
                                    }}
                                    onRemoveItem={(val) => {
                                        setSelectedExperienceItem(null);
                                        const auxCheckout =
                                            checkoutItems.filter((it) => {
                                                return (
                                                    it.checkoutId !==
                                                    val.checkoutId
                                                );
                                            });
                                        setCheckoutItems(auxCheckout);
                                    }}
                                />
                                {/* )} */}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{ marginBottom: '1rem' }}>
                                {/* {renderColumn( */}
                                <h2>
                                    {translate(
                                        'global_bookings.triper_information',
                                        'Triper Information'
                                    )}
                                </h2>
                                {renderBookingForm()}
                                {/* )} */}
                            </Grid>
                        </>
                    ) : (
                        ''
                    )}
                </Grid>
            </Grid>
            <Dialog 
                dialogTitle={'Atencion!'}
                open={quantityOpen && quantityOpenOneTime}
                dialogContent={<Typography variant='h6'>Cuando se cambia la cantidad de personas se debe volver a elegir fecha en todas las actividades del resumen ya que puede ser que no haya disponibildad para la cantidad de personas seleccionada</Typography>}
                hideBack={true}
                handleSubmit={ e => {
                    setQuantity(quantity);
                    setQuantityOpen(false);
                    setQuantityOpenOneTime(false);
                }}
                labelNext={'OK'}
            />
            {/* This for the autoscrollin */}
            <div ref={formEndRef} />
        </Layout>
    );
};

export default BookingNewPage;
